import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Grid } from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'

const defaultSizes = {
  xs: 12,
  sm: 12,
  md: 4
}

const BlogPreview = ({ title, excerpt, link, imgData, index, sizes = {} }) => {
  const sizesWithProps = { ...defaultSizes, ...sizes}
  return (
    <Grid item xs={sizesWithProps.xs} sm={sizesWithProps.sm} md={sizesWithProps.md}>
      <Link to={`/blog/${link}`} >
        <BackgroundImage
          fluid={imgData}
          css={theme => css`
            height: 300px;
            display: flex;
            align-items: flex-end;
            ${theme.boxShadow.lgrey};
            transition: box-shadow 0.3s ease-in;
            :hover {
              ${theme.boxShadow.dgrey};
            }
            :hover p {
              max-height: 1000px;
              opacity: 1;
            }
          `}
        >
          <div
            css={theme => css`
          background-color: ${theme.colors.black_transparent};
          padding: 10px;
          width: 100%;
          color: white;
          overflow: hidden;
        `
            }
          >
            <h3
              css={
                css`
                  margin: 0px;
                  font-size: 22px;
                `
              }
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              css={css`
                p {
                  height: 100%;
                  max-height: 0;
                  margin: 0;
                  opacity: 0;
                  transition: max-height 0.3s ease-in, opacity 0.3s;
                }
              `}
            dangerouslySetInnerHTML={{ __html : excerpt }} />
          </div>
        </BackgroundImage>
      </Link>
    </Grid>
  )
}

export default BlogPreview