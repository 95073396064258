import React, {Fragment} from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid } from "@material-ui/core"
import { css } from '@emotion/core'
import BlogPreview from '../components/BlogPreview'
import HomeInfoBlock from '../components/InfoBlock'

const mdSizes = (index) => {
  if (index === 0) {
    return 8
  } else if (index === 1) {
    return 4
  }
  return 4
}

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title={undefined} url="" description={"We aim to provide you with clear and straightforward information and commentary on sustainable finance, renewable energy, and the green transition - helping you to help the planet."} />
      <Grid
        container
        spacing={1}
      >
        {data.allWordpressPost.edges.map(({ node }, index) => {
          const blogPostSizes = {
            sm: index === 0 ? 12 : 4,
            md: mdSizes(index),
          }
          return (
            <Fragment>
              {index === 1 && <HomeInfoBlock />}
              <BlogPreview
                imgData={
                  node.featured_media.localFile.childImageSharp ?
                  node.featured_media.localFile.childImageSharp.fluid :
                  data.file.childImageSharp.fluid
                }
                title={node.title}
                excerpt={node.excerpt}
                link={node.slug}
                index={index}
                sizes={blogPostSizes}
              />
            </Fragment>
          )
        })}
        <Grid item xs="12" sm="4">
          <Link to="/blog">
            <div
              css={theme => css`
                background-color: ${theme.colors.green};
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;  
              `}
            >
              <h2
                css={theme => css`
                  color: ${theme.colors.white};
                  margin: 0;
                  font-size: 30px;
                  max-width: 140px;
                  text-align: center;
                `}
              >
                See more blog posts
              </h2>
            </div>
          </Link>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressPost(
      sort: {
      fields: [date]
      order: DESC
      }
      limit: 6
    ) {
      edges {
        node {
          title
          excerpt
          slug
          fimg_url {
            source_url
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "images/fallbackImage.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`