import React from 'react';
import { css } from '@emotion/core'
import { Grid } from "@material-ui/core"

const HomeInfoBlock = () => <Grid item xs={12} sm={12} md={4}>
  <div
    css={theme => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      background-color: ${theme.colors.green};
      color: ${theme.colors.white};
      ${theme.boxShadow.lgrey};
      ${theme.breakpoints.md(`min-height: 240px;`)}
    `}
  >
    <h1
      css={theme => css`
        margin: 0;
        ${theme.line(theme.colors.white)}
      `}
    >Green Finance Guide</h1>
    <p
      css={css`
        padding: 0 10px;
        margin: 0;
        font-weight: 600;
        line-height: 1.4;
        max-width: 350px;
      `}
    >We aim to provide you with clear and straightforward information and commentary on sustainable finance, renewable energy, and the green transition - helping you to help the planet.</p>
  </div>
</Grid>

export default HomeInfoBlock;